<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <h1>Klijenti</h1>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/clients/create">
                    <b-btn variant="primary">Novi klijent</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="clients">
            <b-col>
                <osta-table position
                            search-key="name"
                            :items="clients"
                            :fields="clientsFields"
                            :actions="actions"
                            @rowClicked="goToClient"
                            @onAction="action">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'clients',
        data() {
            return {
                clients: null,
                clientsFields: [
                    {
                        key: 'name',
                        label: 'Ime',
                        sortable: true
                    },
                    {
                        key: 'id_number',
                        label: 'ID broj',
                        sortable: false
                    },
                    {
                        key: 'pdv_number',
                        label: 'PDV broj',
                        sortable: false,
                        formatter: (value) => (value) ? value : 'Nije u sustavu'
                    }
                ],
                actions: {
                    'assign': {
                        name: 'Odaberi klijenta',
                        variant: 'success',
                    }
                }
            }
        },
        methods: {
            action: function (action, item) {
                switch (action) {
                    case 'assign':
                        this.$store.dispatch('selectClient', item.id);
                        this.$cookie.set('selected_client', item.id);
                        break;
                }
            },
            goToClient(id) {
                this.$router.push('/clients/' + id);
            },
        },
        created() {
            axiOsta.get(api.clients).then(response => {
                if (response.status === 200) {
                    this.clients = response.data;
                }
            })
        }
    }
</script>
